<template>
<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="vue-editor text-md-h2 text-subtitle-1 font-weight-black mb-5 mb-md-0"
					v-html="trans('brand_guide_page_colors_title')"
				/>
			</v-col>
			<v-col cols="12" md="8">
				<p
					class="vue-editor sub-title mt-md-9 text-md-subtitle-1 text-body-2 grayText--text"
					v-html="trans('brand_guide_page_colors_subtitle')"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<div class="color-box d-flex flex-wrap">
					<v-sheet
						v-for="(color, colorIndex) in colors"
						:key="colorIndex"
						width="100%"
						:max-width="$vuetify.breakpoint.smAndDown ? '100%' : '236'"
						height="160"
						:class="[
							'color-item d-flex flex-column justify-space-between pa-4',
							color.color + '--text'
						]"
						:style="{ backgroundColor: color.background }"
					>
						<span
							class="vue-editor text-subtitle-1 font-weight-bold"
							v-html="trans(color.name)"
						/>
						<div>
							<span
								class="vue-editor d-block text-subtitle-1 font-weight-bold mb-1"
								v-html="trans(color.background)"
							/>
							<span
								class="vue-editor d-block text-body-1 font-weight-bold"
								v-html="trans(color.cipher)"
							/>
						</div>
					</v-sheet>
			</div>
			</v-col>
		</v-row>
</div>
</template>

<script>
export default {
	name: 'Colors',
	computed: {
		colors () {
			return [
				{
					background: this.trans('brand_guide_page_colors_item_1_color'),
					color: 'white',
					name: this.trans('brand_guide_page_colors_item_1_name'),
					cipher: this.trans('brand_guide_page_colors_item_1_code'),
				},
				{
					background: this.trans('brand_guide_page_colors_item_2_color'),
					color: 'white',
					name: this.trans('brand_guide_page_colors_item_2_name'),
					cipher: this.trans('brand_guide_page_colors_item_2_code'),
				},
				{
					background: this.trans('brand_guide_page_colors_item_3_color'),
					color: 'white',
					name: this.trans('brand_guide_page_colors_item_3_name'),
					cipher: this.trans('brand_guide_page_colors_item_3_code'),
				},
				{
					background: this.trans('brand_guide_page_colors_item_4_color'),
					color: 'black',
					name: this.trans('brand_guide_page_colors_item_4_name'),
					cipher: this.trans('brand_guide_page_colors_item_4_code'),
				},
				{
					background: this.trans('brand_guide_page_colors_item_5_color'),
					color: 'white',
					name: this.trans('brand_guide_page_colors_item_5_name'),
					cipher: this.trans('brand_guide_page_colors_item_5_code'),
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	margin-bottom: 150px;
}
.sub-title {
	max-width: 667px;
	margin-bottom: 100px !important;
}
.color-box {
	gap: 25px;
}
.color-item {
	border-radius: 12px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.content {
		margin-bottom: 60px;
	}
	.sub-title {
		margin-bottom: 40px !important;
	}
	.color-box {
		row-gap: 20px;
		margin-right: 0px;
	}
}
</style>
