import Subheader from '@/components/brand-guide/subheader/Subheader.vue'
import SpacingSection from '@/components/brand-guide/SpacingSection'
import Colors from '@/components/brand-guide/Colors'
import LogoList from '@/components/brand-guide/LogoList'

export default {
  name: 'BrandGuide',
  components: {
    Subheader,
    SpacingSection,
    Colors,
    LogoList
  },
	metaInfo () {
		return {
			title: 'Brand Guide',
			meta: [{
				name: 'description',
				content: 'Topic description about "Brand Guide" page',
			},
      {
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Brand Guide',
			},
			{
				name: 'og:description',
				content: 'Topic description about "Brand Guide" page',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Brand Guide',
			},
			{
				name: 'twitter:description',
				content: 'Topic description about "Brand Guide" page',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
    ],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
  data:() => ({
    ourLogoList: [
      {
				id: 1,
        src_svg: require('@/assets/images/brand-logo-tn-color.svg'),
				src_png:require('@/assets/images/brand-logo-tn-color.png'),
				isLight: true
      },
      {
				id: 2,
        src_svg: require('@/assets/images/brand-logo-tn-dark.svg'),
				src_png: require('@/assets/images/brand-logo-tn-dark.png'),
				isLight: false
      },
      {
				id: 3,
        src_svg: require('@/assets/images/brand-logo-tn-light.svg'),
				src_png: require('@/assets/images/brand-logo-tn-light.png'),
				isLight: true
      }
    ],
    alternateList: [
      {
				id: 1,
        src_svg: require('@/assets/images/brand-logo-color.svg'),
				src_png: require('@/assets/images/brand-logo-color.png'),
				isLight: true
      },
      {
				id: 2,
        src_svg: require('@/assets/images/brand-logo-dark.svg'),
				src_png: require('@/assets/images/brand-logo-dark.png'),
				isLight: false
      },
      {
				id: 3,
        src_svg: require('@/assets/images/brand-logo-light.svg'),
				src_png: require('@/assets/images/brand-logo-light.png'),
				isLight: true
      }
    ]
  })
}
