var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"vue-editor text-md-h2 text-subtitle-1 font-weight-black mb-5 mb-md-0",domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"vue-editor sub-title mt-md-9 text-md-subtitle-1 text-body-2 grayText--text",domProps:{"innerHTML":_vm._s(_vm.subtitle)}})])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('div',{staticClass:"logo-list d-flex justify-space-between flex-column flex-md-row"},_vm._l((_vm.list),function(item,index){return _c('v-sheet',{key:index,class:[
							'logo-item d-flex align-center justify-center',
							item.isLight ? '' : 'black'
						],attrs:{"width":"100%","max-width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '372',"height":_vm.$vuetify.breakpoint.smAndDown ? '200' : '240'}},[_c('v-img',{attrs:{"contain":"","height":"80","src":item.src_svg}}),_c('div',{staticClass:"type-box text-body-1"},[_c('a',{class:[
									'vue-editor text-decoration-none type-hover pointer mr-5 mr-md-2',
									item.isLight ? 'grayText--text' : 'white--text'
								],attrs:{"href":item.src_svg,"download":"logo-icon.svg"},domProps:{"innerHTML":_vm._s(_vm.trans('brand_guide_page_svg'))}}),_c('a',{class:[
									'vue-editor text-decoration-none type-hover pointer',
									item.isLight ? 'grayText--text' : 'white--text'
								],attrs:{"href":item.src_png,"download":"logo-icon.png"},domProps:{"innerHTML":_vm._s(_vm.trans('brand_guide_page_png'))}})])],1)}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }