import PreviewLogo from '@/components/brand-guide/PreviewLogo'
export default {
  name: 'Subheader',
  components: {
    PreviewLogo
  },
	computed: {
		previewItems () {
			return [
				{
					id: 1,
					src: require('@/assets/images/brand-guide-preview-icon-1.svg'),
					title: this.trans('brand_guide_page_preview_icon_1_name')
				},
				{
					id: 2,
					src: require('@/assets/images/brand-guide-preview-icon-2.svg'),
					title: this.trans('brand_guide_page_preview_icon_2_name')
				},
				{
					id: 3,
					src: require('@/assets/images/brand-guide-preview-icon-3.svg'),
					title: this.trans('brand_guide_page_preview_icon_3_name')
				}
			]
		}
	}
}
