<template>
<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="vue-editor text-md-h2 text-subtitle-1 font-weight-black mb-5 mb-md-0"
					v-html="title"
				/>
			</v-col>
			<v-col cols="12">
				<p
					class="vue-editor sub-title mt-md-9 text-md-subtitle-1 text-body-2 grayText--text"
					v-html="subtitle"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12" class="d-flex">
				<div class="logo-list d-flex justify-space-between flex-column flex-md-row">
					<v-sheet
						v-for="(item, index) in list"
						:key="index"
						width="100%"
						:max-width="$vuetify.breakpoint.smAndDown ? '100%' : '372'"
						:height="$vuetify.breakpoint.smAndDown ? '200' : '240'"
						:class="[
							'logo-item d-flex align-center justify-center',
							item.isLight ? '' : 'black'
						]"
					>
						<v-img contain height="80" :src="item.src_svg" />
						<div class="type-box text-body-1">
							<a
								:href="item.src_svg"
								download="logo-icon.svg"
								:class="[
									'vue-editor text-decoration-none type-hover pointer mr-5 mr-md-2',
									item.isLight ? 'grayText--text' : 'white--text'
								]"
								v-html="trans('brand_guide_page_svg')"
							/>
							<a
								:href="item.src_png"
								download="logo-icon.png"
								:class="[
									'vue-editor text-decoration-none type-hover pointer',
									item.isLight ? 'grayText--text' : 'white--text'
								]"
								v-html="trans('brand_guide_page_png')"
							/>
						</div>
					</v-sheet>
				</div>
			</v-col>
		</v-row>
</div>
</template>

<script>
export default {
	name: 'LogoList',
	props: {
		title: {
			type: String,
			required: true
		},
		subtitle: {
			type: String,
			required: true
		},
		list: {
			type: Array,
			required: true
		},
	}
}
</script>

<style lang="scss" scoped>
.sub-title {
	max-width: 800px;
}
.logo-list {
	margin-top: 75px;
	width: 100%;
	gap: 20px;
}
.logo-item {
	border-radius: 20px;
	box-shadow: -2px 16px 32px #2D34363D;
	position: relative;
}
.type-box {
	position: absolute;
	bottom: 16px;
	right: 26px;
}
.active-type {
	color: var(--v-mainColor-base);
	padding-bottom: 1px;
	border-bottom: 1px solid var(--v-mainColor-base);
}
.type-hover {
	transition: 0.1s linear;
	&:hover {
		color: var(--v-mainColor-base) !important;
		padding-bottom: 1px !important;
		border-bottom: 1px solid var(--v-mainColor-base) !important;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.logo-list {
		margin-top: 40px;
	}
	.type-box {
		right: 20px;
	}
}
</style>
