<template>
<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="vue-editor text-md-h2 text-subtitle-1 font-weight-black mb-5 mb-md-0"
					v-html="trans('brand_guide_page_spacing_title')"
				/>
			</v-col>
			<v-col cols="12" md="8">
				<p
					class="vue-editor sub-title mt-md-8 text-md-subtitle-1 text-body-2 grayText--text"
					v-html="trans('brand_guide_page_spacing_subtitle')"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<div class="image-box d-flex flex-wrap">
					<v-sheet width="252" height="160">
						<v-img contain height="160" src="@/assets/images/brand-logo-tn-spacing.svg" />
					</v-sheet>
					<v-sheet width="160" height="160">
						<v-img contain height="160" src="@/assets/images/brand-logo-spacing.svg" />
					</v-sheet>
				</div>
			</v-col>
		</v-row>
</div>
</template>

<script>
export default {
	name: 'SpacingSection'
}
</script>

<style lang="scss" scoped>
.sub-title {
	max-width: 800px;
}
.image-box {
	margin-top: 75px;
	gap: 80px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.image-box {
		margin-top: 40px;
		gap: 40px;
	}
}
</style>
